import validate from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/node_modules/nuxt/dist/pages/runtime/validate.js";
import is_45authenticated_45global from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/middleware/isAuthenticated.global.js";
import is_45authorized_45global from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/middleware/isAuthorized.global.ts";
import manifest_45route_45rule from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  is_45authenticated_45global,
  is_45authorized_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/middleware/auth.ts"),
  "can-access-route": () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/middleware/canAccessRoute.js"),
  "feature-enabled": () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/middleware/featureEnabled.ts"),
  guest: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/middleware/guest.ts"),
  unverified: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/middleware/unverified.ts"),
  verified: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/middleware/verified.ts")
}
export function getSdk(client, withWrapper = (action, _operationName, _operationType) => action()) {
  return {
    newsBanner: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query newsBanner ($language: [String], $isActive: Boolean) {
    globalSets (language: $language, isActive: $isActive) {
        ... on newsBanner_GlobalSet {
            titleText
            contentText
            activeFrom
            activeTo
            isDismissible
            image {
                alt
                url
                enabled
                width
                height
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'newsBanner', 'query'),
    offerBanner: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query offerBanner ($language: [String], $isActive: Boolean) {
    globalSets (language: $language, isActive: $isActive) {
        ... on offerBanner_GlobalSet {
            contentText
            activeFrom
            activeTo
            isDismissible
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'offerBanner', 'query'),
    welcomeModal: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query welcomeModal ($language: [String]) {
    globalSets (language: $language) {
        ... on welcomeModal_GlobalSet {
            titleText
            contentText
            image {
                alt
                url
                enabled
                width
                height
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'welcomeModal', 'query')
  }
}
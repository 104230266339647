import { default as activatemP8Q4Xx6ddMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/auth/activate.vue?macro=true";
import { default as indexId5h3MOeJeMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/auth/index.vue?macro=true";
import { default as restore9h7cvWxbBZMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/auth/organisations/restore.vue?macro=true";
import { default as register_45completeydGgipPpNkMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/auth/register-complete.vue?macro=true";
import { default as registerIWtTxurWyoMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/auth/register.vue?macro=true";
import { default as _91token_93rVHOGxjE1jMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/boards/[token].vue?macro=true";
import { default as indexERItQko1lCMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/buzzer/index.vue?macro=true";
import { default as _91id_93PxJwQ2gNE2Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/dashboards/[id].vue?macro=true";
import { default as indexjMsBVUQaqSMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/dashboards/index.vue?macro=true";
import { default as _91token_93mmQx3SF1AsMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/dashboards/token/[token].vue?macro=true";
import { default as tokenlrKOmIhPNsMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/dashboards/token.vue?macro=true";
import { default as demoQ28HDxj8aMMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/demo.vue?macro=true";
import { default as indexIDcfnmZErIMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/events/index.vue?macro=true";
import { default as impressumg2uPka5a3KMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/impressum.vue?macro=true";
import { default as indexPqIlJAdwIPMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/index.vue?macro=true";
import { default as _91id_93KWy1HhoYpnMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/leaderboards/[id].vue?macro=true";
import { default as indexkSSwcyY3gXMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/leaderboards/index.vue?macro=true";
import { default as _91id_9327W2EC6ZbgMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/deals/[id].vue?macro=true";
import { default as indexa22QJXtT0oMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/deals/index.vue?macro=true";
import { default as _91id_93Wl6tqddpbTMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/devices/[id].vue?macro=true";
import { default as _91id_93u8Nlb2h3H3Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/devices/devicegroup/[id].vue?macro=true";
import { default as HSLtoRGBQpRS6u4rMWMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/devices/HSLtoRGB.js?macro=true";
import { default as indexB78muNan8KMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/devices/index.vue?macro=true";
import { default as RGBtoHSL4Sq6q6CEyzMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/devices/RGBtoHSL.js?macro=true";
import { default as _91id_93vFDXuir86wMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/eventTypes/[id].vue?macro=true";
import { default as indexl1d5pGKT4KMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/eventTypes/index.vue?macro=true";
import { default as _91id_93BgIB3qyaqTMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/goals/[id].vue?macro=true";
import { default as indexPnSepoFPYbMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/goals/index.vue?macro=true";
import { default as _91id_93dhc5j183sMMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/groups/[id].vue?macro=true";
import { default as indexXhBigQsBozMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/groups/index.vue?macro=true";
import { default as indexdhqu3mTWH8Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/settings/index.vue?macro=true";
import { default as index6NoYDngyxpMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/subscriptions/index.vue?macro=true";
import { default as _91id_93QfDk3S508gMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/users/[id].vue?macro=true";
import { default as indexcEsAwLhWo4Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/users/index.vue?macro=true";
import { default as _91id_93bisLuwSieNMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/sounds/[id].vue?macro=true";
import { default as entriesfUydcqOGsdMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/sounds/index/entries.vue?macro=true";
import { default as playlistsaedW491y4GMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/sounds/index/playlists.vue?macro=true";
import { default as indexPD3qj3i6pUMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/sounds/index.vue?macro=true";
import { default as indexPtwuoP90UWMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/devices/index.vue?macro=true";
import { default as _91id_93aLnPtlZhBpMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/firmware/[id].vue?macro=true";
import { default as index8th20pqVPTMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/firmware/index.vue?macro=true";
import { default as _91id_93goTzLZRgQCMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/hardware/[id].vue?macro=true";
import { default as indexIPAcmm4rA2Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/hardware/index.vue?macro=true";
import { default as indexba5pbDDLE5Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/logs/index.vue?macro=true";
import { default as _91id_93Xdhe41ljqSMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/notificationTemplates/[id].vue?macro=true";
import { default as indexdUHAS3ZbFEMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/notificationTemplates/index.vue?macro=true";
import { default as _91id_93IWEKZXpMZtMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/organisations/[id].vue?macro=true";
import { default as indexNkrWvlSPZCMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/organisations/index.vue?macro=true";
import { default as _91id_93Bs30hbwfVMMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/plans/[id].vue?macro=true";
import { default as indexwmoqhgynxmMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/plans/index.vue?macro=true";
import { default as releaselogTmDXn4v8noMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/releaselog.vue?macro=true";
import { default as toolsiMCjGzUVr9Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/tools.vue?macro=true";
import { default as _91id_938N1lD01u6gMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/users/[id].vue?macro=true";
import { default as indexp9GHkotvGfMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/users/index.vue?macro=true";
export default [
  {
    name: "auth-activate",
    path: "/auth/activate",
    meta: activatemP8Q4Xx6ddMeta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/auth/activate.vue")
  },
  {
    name: "auth",
    path: "/auth",
    meta: indexId5h3MOeJeMeta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/auth/index.vue")
  },
  {
    name: "auth-organisations-restore",
    path: "/auth/organisations/restore",
    meta: restore9h7cvWxbBZMeta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/auth/organisations/restore.vue")
  },
  {
    name: "auth-register-complete",
    path: "/auth/register-complete",
    meta: register_45completeydGgipPpNkMeta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/auth/register-complete.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerIWtTxurWyoMeta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/auth/register.vue")
  },
  {
    name: "boards-token",
    path: "/boards/:token()",
    meta: _91token_93rVHOGxjE1jMeta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/boards/[token].vue")
  },
  {
    name: "buzzer",
    path: "/buzzer",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/buzzer/index.vue")
  },
  {
    name: "dashboards-id",
    path: "/dashboards/:id()",
    meta: _91id_93PxJwQ2gNE2Meta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/dashboards/[id].vue")
  },
  {
    name: "dashboards",
    path: "/dashboards",
    meta: indexjMsBVUQaqSMeta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/dashboards/index.vue")
  },
  {
    name: "dashboards-token",
    path: "/dashboards/token",
    meta: tokenlrKOmIhPNsMeta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/dashboards/token.vue"),
    children: [
  {
    name: "dashboards-token-token",
    path: ":token()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/dashboards/token/[token].vue")
  }
]
  },
  {
    name: "demo",
    path: "/demo",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/demo.vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/events/index.vue")
  },
  {
    name: "impressum",
    path: "/impressum",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/impressum.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/index.vue")
  },
  {
    name: "leaderboards-id",
    path: "/leaderboards/:id()",
    meta: _91id_93KWy1HhoYpnMeta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/leaderboards/[id].vue")
  },
  {
    name: "leaderboards",
    path: "/leaderboards",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/leaderboards/index.vue")
  },
  {
    name: "organisation-deals-id",
    path: "/organisation/deals/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/deals/[id].vue")
  },
  {
    name: "organisation-deals",
    path: "/organisation/deals",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/deals/index.vue")
  },
  {
    name: "organisation-devices-id",
    path: "/organisation/devices/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/devices/[id].vue")
  },
  {
    name: "organisation-devices-devicegroup-id",
    path: "/organisation/devices/devicegroup/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/devices/devicegroup/[id].vue")
  },
  {
    name: "organisation-devices-HSLtoRGB",
    path: "/organisation/devices/HSLtoRGB",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/devices/HSLtoRGB.js")
  },
  {
    name: "organisation-devices",
    path: "/organisation/devices",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/devices/index.vue")
  },
  {
    name: "organisation-devices-RGBtoHSL",
    path: "/organisation/devices/RGBtoHSL",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/devices/RGBtoHSL.js")
  },
  {
    name: "organisation-eventTypes-id",
    path: "/organisation/eventTypes/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/eventTypes/[id].vue")
  },
  {
    name: "organisation-eventTypes",
    path: "/organisation/eventTypes",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/eventTypes/index.vue")
  },
  {
    name: "organisation-goals-id",
    path: "/organisation/goals/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/goals/[id].vue")
  },
  {
    name: "organisation-goals",
    path: "/organisation/goals",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/goals/index.vue")
  },
  {
    name: "organisation-groups-id",
    path: "/organisation/groups/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/groups/[id].vue")
  },
  {
    name: "organisation-groups",
    path: "/organisation/groups",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/groups/index.vue")
  },
  {
    name: "organisation-settings",
    path: "/organisation/settings",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/settings/index.vue")
  },
  {
    name: "organisation-subscriptions",
    path: "/organisation/subscriptions",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/subscriptions/index.vue")
  },
  {
    name: "organisation-users-id",
    path: "/organisation/users/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/users/[id].vue")
  },
  {
    name: "organisation-users",
    path: "/organisation/users",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/organisation/users/index.vue")
  },
  {
    name: "sounds-id",
    path: "/sounds/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/sounds/[id].vue")
  },
  {
    name: "sounds",
    path: "/sounds",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/sounds/index.vue"),
    children: [
  {
    name: "sounds-index-entries",
    path: "entries",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/sounds/index/entries.vue")
  },
  {
    name: "sounds-index-playlists",
    path: "playlists",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/sounds/index/playlists.vue")
  }
]
  },
  {
    name: "system-devices",
    path: "/system/devices",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/devices/index.vue")
  },
  {
    name: "system-firmware-id",
    path: "/system/firmware/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/firmware/[id].vue")
  },
  {
    name: "system-firmware",
    path: "/system/firmware",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/firmware/index.vue")
  },
  {
    name: "system-hardware-id",
    path: "/system/hardware/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/hardware/[id].vue")
  },
  {
    name: "system-hardware",
    path: "/system/hardware",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/hardware/index.vue")
  },
  {
    name: "system-logs",
    path: "/system/logs",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/logs/index.vue")
  },
  {
    name: "system-notificationTemplates-id",
    path: "/system/notificationTemplates/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/notificationTemplates/[id].vue")
  },
  {
    name: "system-notificationTemplates",
    path: "/system/notificationTemplates",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/notificationTemplates/index.vue")
  },
  {
    name: "system-organisations-id",
    path: "/system/organisations/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/organisations/[id].vue")
  },
  {
    name: "system-organisations",
    path: "/system/organisations",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/organisations/index.vue")
  },
  {
    name: "system-plans-id",
    path: "/system/plans/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/plans/[id].vue")
  },
  {
    name: "system-plans",
    path: "/system/plans",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/plans/index.vue")
  },
  {
    name: "system-releaselog",
    path: "/system/releaselog",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/releaselog.vue")
  },
  {
    name: "system-tools",
    path: "/system/tools",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/tools.vue")
  },
  {
    name: "system-users-id",
    path: "/system/users/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/users/[id].vue")
  },
  {
    name: "system-users",
    path: "/system/users",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250123161000/client/pages/system/users/index.vue")
  }
]
<template>
    <v-field
        v-model="model"
        :name="name"
        :label="label"
        :rules="rules"
        v-slot="{ field, errors, errorMessage, meta}"
        @input="$emit('update:modelValue', $event.target.value)">
        <div
            v-bind="$attrs"
            :class="inputGroup ? 'input-group ' + cssClass:''">

            <slot name="input-group-pre" v-if="inputGroup && inputGroupPre">
                <span class="input-group-text">...</span>
            </slot>

            <div class="form-floating" :class="inputGroup ? '':cssClass">
                <slot :field="field">
                    <input v-bind="field" :type="type" :min="min" class="form-control" :placeholder="label" :autocomplete="autocomplete" :class="{'is-invalid':!meta.valid && meta.touched}" :id="'input_'+name" :disabled="isDisabled">
                </slot>

                <label v-if="label" :for="'input_'+name">{{ label }}</label>

                <div v-if="!inputGroup && (errors[0] || formErrors[name])" class="invalid-feedback d-block">{{ errors[0] }}<!--{{formErrors[name]}}--></div>
                <slot name="help-text"></slot>
            </div>

            <slot name="input-group-post" v-if="inputGroup && inputGroupPost">
                <span class="input-group-text">...</span>
            </slot>

            <div v-if="inputGroup && (errors[0] || formErrors[name])" class="invalid-feedback d-block">{{ errors[0] }}<!--{{formErrors[name]}}--></div>
        </div>
    </v-field>
</template>

<script>
export default {
    name: 'BaseInputFloating',

    computed: {
        model: {
            get() {
                return this.modelValue
            },
            set(newValue) {
                // Note: we are using destructuring assignment syntax here.
                this.$emit('update:modelValue', newValue)
            }
        }
    },

    props: {
        name: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        min: {
            type: Number,
            default: null
        },
        inputGroup: {
            type: Boolean,
            default: false,
        },
        inputGroupPre: {
            type: Boolean,
            default: false,
        },
        inputGroupPost: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: ''
        },
        cssClass: {
            type: String,
            default: 'mb-3'
        },
        modelValue: {
            type: [String, Number],
            default: ''
        },
        formErrors: {
            type: Object,
            default: {}
        },
        rules: {
            type: [String, Array, Object],
            description: 'Vee validate validation rules',
            default: ''
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        autocomplete: {
            type: String,
            default: null
        }
    },
};
</script>
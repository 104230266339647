import { default as activateZCzsZaSb2jMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/auth/activate.vue?macro=true";
import { default as indexbYWpLfKSbtMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/auth/index.vue?macro=true";
import { default as register_45completeamS8jd6TsYMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/auth/register-complete.vue?macro=true";
import { default as registerKXQmwzuJ50Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/auth/register.vue?macro=true";
import { default as restoreatpkxKBSqpMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/auth/restore.vue?macro=true";
import { default as indexP6DEmF42pkMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/buzzer/index.vue?macro=true";
import { default as _91id_93tAJ5HBecSGMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/dashboards/[id].vue?macro=true";
import { default as indexxrs511ZZAcMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/dashboards/index.vue?macro=true";
import { default as _91token_93wm5VUDpuxZMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/dashboards/token/[token].vue?macro=true";
import { default as tokenqNluyeBCEAMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/dashboards/token.vue?macro=true";
import { default as demoSG4BvsA1VkMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/demo.vue?macro=true";
import { default as indexfQphhjHy8WMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/events/index.vue?macro=true";
import { default as impressumO6ZbKCCHpCMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/impressum.vue?macro=true";
import { default as indexh4AI45UugbMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/index.vue?macro=true";
import { default as _91id_93Ym5ZzyBMQ5Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/deals/[id].vue?macro=true";
import { default as indexGNUTlq2NH1Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/deals/index.vue?macro=true";
import { default as _91id_93gWY4ngtUxyMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/devices/[id].vue?macro=true";
import { default as _91id_93Hc6O2ywPXuMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/devices/devicegroup/[id].vue?macro=true";
import { default as HSLtoRGBaUR8Pd2kNoMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/devices/HSLtoRGB.js?macro=true";
import { default as indexlIObNolJtaMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/devices/index.vue?macro=true";
import { default as RGBtoHSLlQQHdDp117Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/devices/RGBtoHSL.js?macro=true";
import { default as _91id_93E7kZPDX6BvMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/eventTypes/[id].vue?macro=true";
import { default as index06BykAOLT5Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/eventTypes/index.vue?macro=true";
import { default as _91id_93bfzcYqP4gSMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/goals/[id].vue?macro=true";
import { default as indexUoC4n9Kx80Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/goals/index.vue?macro=true";
import { default as _91id_93DKWsITNs7LMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/groups/[id].vue?macro=true";
import { default as indexcemfDKi3qyMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/groups/index.vue?macro=true";
import { default as indexsrtFUMwRABMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/settings/index.vue?macro=true";
import { default as indexgqWPvHN3gmMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/subscriptions/index.vue?macro=true";
import { default as _91id_938BhVhsAYBHMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/users/[id].vue?macro=true";
import { default as indexlYgNpF4aPEMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/users/index.vue?macro=true";
import { default as _91id_93nohsvP0Xv2Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/sounds/[id].vue?macro=true";
import { default as entries4R2sl12XJAMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/sounds/index/entries.vue?macro=true";
import { default as playlists1Xwu26BtnhMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/sounds/index/playlists.vue?macro=true";
import { default as indexUJU4IlLmzRMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/sounds/index.vue?macro=true";
import { default as index1UJlXQQC4GMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/devices/index.vue?macro=true";
import { default as _91id_93UtvAbgfK9TMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/firmware/[id].vue?macro=true";
import { default as indexPVc2Z99WmvMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/firmware/index.vue?macro=true";
import { default as _91id_93BsiOmTgDgNMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/hardware/[id].vue?macro=true";
import { default as indexEgJhddzCbwMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/hardware/index.vue?macro=true";
import { default as indexP8HoPXTdvYMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/logs/index.vue?macro=true";
import { default as _91id_93dhFcX2PO0RMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/notificationTemplates/[id].vue?macro=true";
import { default as indexbULMlazxSlMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/notificationTemplates/index.vue?macro=true";
import { default as _91id_93bxKoPwXqdBMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/organisations/[id].vue?macro=true";
import { default as indexW3bE4lPqB9Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/organisations/index.vue?macro=true";
import { default as _91id_93cTtHWZGlcDMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/plans/[id].vue?macro=true";
import { default as indexznc29kpQk0Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/plans/index.vue?macro=true";
import { default as releaselog4nY7rBnTP4Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/releaselog.vue?macro=true";
import { default as toolsgReWvlF3zZMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/tools.vue?macro=true";
import { default as _91id_93T48uusM6YCMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/users/[id].vue?macro=true";
import { default as indexroRNZTGSBxMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/users/index.vue?macro=true";
export default [
  {
    name: "auth-activate",
    path: "/auth/activate",
    meta: activateZCzsZaSb2jMeta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/auth/activate.vue")
  },
  {
    name: "auth",
    path: "/auth",
    meta: indexbYWpLfKSbtMeta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/auth/index.vue")
  },
  {
    name: "auth-register-complete",
    path: "/auth/register-complete",
    meta: register_45completeamS8jd6TsYMeta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/auth/register-complete.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerKXQmwzuJ50Meta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/auth/register.vue")
  },
  {
    name: "auth-restore",
    path: "/auth/restore",
    meta: restoreatpkxKBSqpMeta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/auth/restore.vue")
  },
  {
    name: "buzzer",
    path: "/buzzer",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/buzzer/index.vue")
  },
  {
    name: "dashboards-id",
    path: "/dashboards/:id()",
    meta: _91id_93tAJ5HBecSGMeta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/dashboards/[id].vue")
  },
  {
    name: "dashboards",
    path: "/dashboards",
    meta: indexxrs511ZZAcMeta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/dashboards/index.vue")
  },
  {
    name: "dashboards-token",
    path: "/dashboards/token",
    meta: tokenqNluyeBCEAMeta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/dashboards/token.vue"),
    children: [
  {
    name: "dashboards-token-token",
    path: ":token()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/dashboards/token/[token].vue")
  }
]
  },
  {
    name: "demo",
    path: "/demo",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/demo.vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/events/index.vue")
  },
  {
    name: "impressum",
    path: "/impressum",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/impressum.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/index.vue")
  },
  {
    name: "organisation-deals-id",
    path: "/organisation/deals/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/deals/[id].vue")
  },
  {
    name: "organisation-deals",
    path: "/organisation/deals",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/deals/index.vue")
  },
  {
    name: "organisation-devices-id",
    path: "/organisation/devices/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/devices/[id].vue")
  },
  {
    name: "organisation-devices-devicegroup-id",
    path: "/organisation/devices/devicegroup/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/devices/devicegroup/[id].vue")
  },
  {
    name: "organisation-devices-HSLtoRGB",
    path: "/organisation/devices/HSLtoRGB",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/devices/HSLtoRGB.js")
  },
  {
    name: "organisation-devices",
    path: "/organisation/devices",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/devices/index.vue")
  },
  {
    name: "organisation-devices-RGBtoHSL",
    path: "/organisation/devices/RGBtoHSL",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/devices/RGBtoHSL.js")
  },
  {
    name: "organisation-eventTypes-id",
    path: "/organisation/eventTypes/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/eventTypes/[id].vue")
  },
  {
    name: "organisation-eventTypes",
    path: "/organisation/eventTypes",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/eventTypes/index.vue")
  },
  {
    name: "organisation-goals-id",
    path: "/organisation/goals/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/goals/[id].vue")
  },
  {
    name: "organisation-goals",
    path: "/organisation/goals",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/goals/index.vue")
  },
  {
    name: "organisation-groups-id",
    path: "/organisation/groups/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/groups/[id].vue")
  },
  {
    name: "organisation-groups",
    path: "/organisation/groups",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/groups/index.vue")
  },
  {
    name: "organisation-settings",
    path: "/organisation/settings",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/settings/index.vue")
  },
  {
    name: "organisation-subscriptions",
    path: "/organisation/subscriptions",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/subscriptions/index.vue")
  },
  {
    name: "organisation-users-id",
    path: "/organisation/users/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/users/[id].vue")
  },
  {
    name: "organisation-users",
    path: "/organisation/users",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/organisation/users/index.vue")
  },
  {
    name: "sounds-id",
    path: "/sounds/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/sounds/[id].vue")
  },
  {
    name: "sounds",
    path: "/sounds",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/sounds/index.vue"),
    children: [
  {
    name: "sounds-index-entries",
    path: "entries",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/sounds/index/entries.vue")
  },
  {
    name: "sounds-index-playlists",
    path: "playlists",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/sounds/index/playlists.vue")
  }
]
  },
  {
    name: "system-devices",
    path: "/system/devices",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/devices/index.vue")
  },
  {
    name: "system-firmware-id",
    path: "/system/firmware/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/firmware/[id].vue")
  },
  {
    name: "system-firmware",
    path: "/system/firmware",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/firmware/index.vue")
  },
  {
    name: "system-hardware-id",
    path: "/system/hardware/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/hardware/[id].vue")
  },
  {
    name: "system-hardware",
    path: "/system/hardware",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/hardware/index.vue")
  },
  {
    name: "system-logs",
    path: "/system/logs",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/logs/index.vue")
  },
  {
    name: "system-notificationTemplates-id",
    path: "/system/notificationTemplates/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/notificationTemplates/[id].vue")
  },
  {
    name: "system-notificationTemplates",
    path: "/system/notificationTemplates",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/notificationTemplates/index.vue")
  },
  {
    name: "system-organisations-id",
    path: "/system/organisations/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/organisations/[id].vue")
  },
  {
    name: "system-organisations",
    path: "/system/organisations",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/organisations/index.vue")
  },
  {
    name: "system-plans-id",
    path: "/system/plans/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/plans/[id].vue")
  },
  {
    name: "system-plans",
    path: "/system/plans",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/plans/index.vue")
  },
  {
    name: "system-releaselog",
    path: "/system/releaselog",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/releaselog.vue")
  },
  {
    name: "system-tools",
    path: "/system/tools",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/tools.vue")
  },
  {
    name: "system-users-id",
    path: "/system/users/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/users/[id].vue")
  },
  {
    name: "system-users",
    path: "/system/users",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250127064806/client/pages/system/users/index.vue")
  }
]
<template>
    <div class="position-relative" v-if="isEnabled && !isDismissed">
        <div class="p-1 d-flex align-items-center justify-content-center bg-primary text-black">
            <div v-html="content" class="no-child-margin"/>
            <button
                v-if="isDismissible"
                type="button"
                class="position-absolute end-0 border-0 bg-transparent pe-3"
                @click="dismiss()">
                <span class="sr-only">Close</span>
                <font-awesome-icon icon="fa-solid fa-xmark" aria-hidden="true" size="lg"/>
            </button>
        </div>
    </div>
</template>

<script setup>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {getShortHash} from "~/utils/hashing";
import {checkActiveTimeRange} from "~/utils/timeRange";

const {locale} = useI18n()

const isEnabled = ref(false)
const content = ref('')
const isDismissible = ref(false)
const isDismissed = ref(false)

function dismiss() {
    const hash = getShortHash(content.value)
    useLocalStorage(`offer-banner-${hash}-dismissed`, false).value = true
    isDismissed.value = true
}

onMounted(async () => {
    useAsyncGql('offerBanner', {
        language: [locale.value],
        isActive: true,
    }).then(res => {
        if (JSON.stringify(res.data.value.globalSets[1]) === JSON.stringify({})) {
            return
        }
        const data = res.data.value.globalSets[1]

        // Check if the current date is within the start and end date
        if (!checkActiveTimeRange(data.activeFrom, data.activeTo)) {
            return
        }

        isEnabled.value = true
        content.value = data.contentText

        isDismissible.value = data.isDismissible
        const hash = getShortHash(data.contentText)
        isDismissed.value = isDismissible.value && useLocalStorage(`offer-banner-${hash}-dismissed`, false).value
    })
})
</script>

<style scoped>
.no-child-margin > * {
    margin: 0 !important;
}
</style>
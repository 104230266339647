<template>
    <div class="min-vh-100 d-flex flex-column">
        <div class="sticky-top" ref="pageHeader">
            <NuxtErrorBoundary>
                <OfferBanner/>
            </NuxtErrorBoundary>
            <impersonate-badge/>
            <NotificationSettingBanner/>
            <Header/>
        </div>
        <div class="w-100 d-flex justify-content-center position-relative">

            <Confetti/>

            <div class="w-100 min-vh-100 pb-5 pb-md-0">
                <slot/>
            </div>
        </div>
        <FooterLinks :absolute="false" class="d-none d-md-flex"/>
        <MobileNavigation class="d-md-none"/>
        <EditUserModal
            :show="showNewEditUserModal"
            @update:show="showNewEditUserModal = $event"
        />
    </div>
</template>

<script setup>
import MobileNavigation from "@/components/layouts/MobileNavigation";
import Header from "@/components/layouts/Header";
import ImpersonateBadge from "@/components/layouts/ImpersonateBadge";
import {useWebsockets} from '@/store/websockets'
import FooterLinks from "~/components/layouts/FooterLinks.vue";
import NotificationSettingBanner from "~/components/layouts/NotificationSettingBanner.vue";
import {useI18n} from 'vue-i18n';
import {useEventBus} from '@vueuse/core'
import EditUserModal from "@/components/pages/users/EditUserModal";
import {ref} from "vue";
import OfferBanner from "@/components/layouts/OfferBanner";

const route = useRoute()
const {t} = useI18n()
const showNewEditUserModal = ref(false)

onMounted(() => {
    useWebsockets().init()
})

useEventBus('showNewEditUserModal').on(() => {
    showNewEditUserModal.value = true
})
</script>